<template>
    <el-dialog
        append-to-body
        :title="$t('shang-pin-yu-lan')"
        center
        :visible.sync="showDialog"
        width="1260px"
        class="product-preview"
    >
        <div class="product">
            <div class="product-main">
                <div class="product-top">
                    <product-img :img="productInfo.img" :video="productInfo.video"></product-img>

                    <product-top
                        preview
                        :productInfo="productInfo"
                        :applicationField="applicationField"
                        :tags="tagList"
                    >
                    </product-top>
                </div>
            </div>

            <el-container class="product-main">
                <el-aside width="200px">
                    <Product-related preview />
                </el-aside>
                <el-main>
                    <el-popover placement="bottom" width="200" trigger="hover" v-if="pdf.length > 0">
                        <el-link slot="reference" :underline="false" class="pdf">
                            <img src="../../assets/xiangqing_icon_pdf.png" alt="" />
                            <span>{{ $t('pdf-zi-liao') }}</span>
                        </el-link>

                        <div class="pdf-info" v-for="(item, index) in pdf" :key="index">
                            <img src="../../assets/xiangqing_icon_pdf.png" alt="" />

                            <span> {{ item.name }}</span>

                            <el-button type="warning" size="mini" plain @click="downDload(item.url)">
                                {{ $t('cha-kan') }}
                            </el-button>
                        </div>
                    </el-popover>

                    <el-tabs type="card" class="detailTab">
                        <el-tab-pane :label="$t('chan-pin-xiang-qing')">
                            <ProductDetailInfo
                                :productInfo="productInfo"
                                :applicationField="applicationField"
                                :tags="alltags"
                                :productCategory="productCategory"
                            ></ProductDetailInfo>
                        </el-tab-pane>
                        <el-tab-pane :label="$t('xiang-xi-can-shu')">
                            <ProductParameter :productParameters="productParameters"></ProductParameter>
                        </el-tab-pane>
                    </el-tabs>
                </el-main>
            </el-container>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import ProductImg from '../../components/product/ImgZoom';
import ProductTop from '../../components/product/ProductTop';
import ProductRelated from '../../components/product/ProductRelated';

import ProductDetailInfo from '../../components/product/ProductDetailInfo';
import ProductParameter from '../../components/product/ProductParameter.vue';
import { saveBrowse, saveStatistics } from '../../utils/CommonApi';

export default {
    name: 'product',
    props: {
        productInfo: {
            type: Object,
            default: () => {
                return {
                    img: ''
                };
            }
        },
        productParameters: {
            type: Array,
            default: () => {
                return [];
            }
        },
        applicationField: {
            type: Array,
            default: () => {
                return [];
            }
        },
        productCategory: {
            type: Object,
            default: () => {
                return {};
            }
        },
        tags: {}
    },
    data() {
        return {
            productId: 0,
            showDialog: false
        };
    },
    computed: {
        ...mapState(['userInfo']),
        title() {
            return this.$i18n.locale === 'zh'
                ? this.productInfo.chName
                : this.productInfo.enName || this.productInfo.chName;
        },
        alltags() {
            let list = [];
            if (this.productInfo.customTag) {
                list = this.getName(this.productInfo, ['customTag', 'enCustomTag']).split(',') || [];
            }

            return [...this.tags, ...list];
        },
        pdf() {
            return this.productInfo.pdf1 ? this.productInfo.pdf1 : [];
        }
    },
    watch: {
        $route() {
            if (this.$route.query.id) {
                this.productId = this.$route.query.id;
                this.getDetail();
                document.documentElement?.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.productId = this.$route.query.id;
            // this.getDetail();
        }
    },
    methods: {
        getDetail() {
            this.$http.get(`/product/getDto/${this.productId}`).then(res => {
                this.productInfo = res;
            });
        },
        downDload(url) {
            window.open('http://www.imttech.cn/pdf/web/viewer.html?file=' + url);
        }
    },
    components: {
        ProductImg,
        ProductTop,
        ProductRelated,
        ProductDetailInfo,
        ProductParameter
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.product {
    background-color: #f5f7fa;
    padding: 30px;
}

.product-main {
    // @include center-content(410);

    .el-breadcrumb {
        padding: 30px 0;
    }

    box-sizing: content-box;
}

.product-top {
    display: flex;
    background-color: #fff;

    .product-info {
        position: relative;
        z-index: 1;
    }
    .productImg {
        position: relative;
        z-index: 2;
    }
}

.el-container {
    margin-top: 84px;
}

.el-main {
    padding: 0 0 0 20px;
    position: relative;
}

.pdf-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        margin-left: 10px;
        flex-grow: 1;
    }

    &:hover {
        span {
            color: #ffa526;
        }
    }
}
.pdf-info + .pdf-info {
    margin-top: 15px;
}
</style>

<style lang="scss">
.detailTab {
    .el-tabs__header.is-top {
        margin-bottom: 0px;
        border-width: 0px;
    }
    .el-tabs__nav-wrap.is-top {
        background-color: #dcdfe6;
        margin-bottom: 0px;
    }
    &.el-tabs--card > .el-tabs__header .el-tabs__item {
        border-width: 0px;
        height: 56px;
        line-height: 56px;
        position: relative;
    }
    &.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
        background-color: #fff;
        &::before {
            content: '';
            height: 3px;
            background: #ffa526;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }
    .el-tabs__nav.is-top {
        border-width: 0px;
    }
}

.pdf {
    position: absolute;
    right: 30px;
    z-index: 20;
    top: 18px;
    .el-link--inner {
        display: flex;
        align-items: center;
        img {
            width: 20px;
            height: 20px;
        }

        span {
            font-size: 14px;
            margin-left: 10px;
            color: #292c33;
            line-height: 20px;
        }
    }
}

.product-preview {
    .el-dialog__header {
        background-color: #fff;
    }
    .el-dialog {
        // max-width: 1260px;
    }
    .el-dialog__body {
        padding: 0;
    }

    .product-main {
        padding: 0 0;
    }
}

@media screen and (max-width: 1200px) {
    .product-preview {
        .el-dialog {
            transform: scale(0.8);
            margin-top: 0px !important;
        }
    }
}
</style>
