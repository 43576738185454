<template>
    <!-- 产品分类 选择组件 -->

    <Cascader
        v-model="value"
        :list="list"
        :placeholder="$t('qing-xuan-ze-huo-zhe-shou-dong-shu-ru-wen-zi-hou-an-hui-che-tian-jia')"
    ></Cascader>
</template>
<script>
import { delChild, getInfoByKey } from '../../utils/Array';
import Cascader from '../Cascader.vue';
export default {
    name: 'IndustrySelect',
    props: {
        ids: {
            type: Array,
            default: () => {
                return [];
            }
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            list: [],
            value: {
                ids: [],
                labels: []
            }
        };
    },
    computed: {
        nowIds() {
            return this.value.ids || [];
        },
        nowLable() {
            return (this.value.labels || []).join(',');
        },
        nowIdInfos() {
            const ids = [...this.nowIds];
            return ids.map(item => {
                return getInfoByKey(this.list, item);
            });
        }
    },
    watch: {
        nowIds() {
            this.$emit('update:ids', this.nowIds);
        },
        nowLable() {
            this.$emit('update:label', this.nowLable);
        },
        ids() {
            console.log(this.ids);
            if (this.ids !== this.nowIds) {
                this.value.ids = this.ids;
            }
        },
        label() {
            if (this.label !== this.nowLable) {
                this.value.labels = this.label.split(',');
            }
        },
        nowIdInfos() {
            this.$emit('update:tags', this.nowIdInfos);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$http
                .get('/productTag/tags')
                .then(res => {
                    this.list = res;
                })
                .catch(e => {
                    // console.log(e);
                    this.$message.error(e.error);
                });
        });
    },
    methods: {
        select() {
            this.$nextTick(() => {
                this.$emit('input', this.value1);
            });
        }
    },
    components: {
        Cascader
    }
};
</script>
