<template>
    <el-select
        v-model="value1"
        @input="change"
        multiple
        filterable
        allow-create
        default-first-option
        :placeholder="placeholder"
        style="width: 100%"
    >
        <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item"> </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'createSelect',
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            options: [],
            value1: []
        };
    },
    computed: {
        values() {
            return this.value ? this.value.split(',') : [];
        }
    },
    watch: {
        value() {
            if (this.value1 !== this.values) {
                console.log(this.values);
                this.value1 = [...this.values];
            }
        }
    },
    mounted() {
        if (this.value1 !== this.values) {
            this.value1 = [...this.values];
        }
    },
    methods: {
        change(val) {
            console.log(val);
            this.$emit('input', val.length > 0 ? val.join(',') : '');
        }
    }
};
</script>
