<template>
    <div class="edit-view">
        <el-form
            :model="form"
            :rules="rules"
            ref="form"
            :label-width="isChinese ? '1.9rem' : '2.5rem'"
            label-position="right"
            size="small"
            style="max-width: 100%;"
        >
            <div class="userTitle">
                <span>{{ $t('ji-ben-xin-xi') }}</span>
                <p class="dividingLine"></p>
            </div>

            <el-form-item prop="chName" :label="$t('chan-pin-zhu-biao-ti')" :rules="rulesRequire">
                <div class="input-list">
                    <div class="input2">
                        <el-input
                            v-model="form.chName"
                            maxlength="60"
                            :placeholder="
                                isChinese ? $t('qing-shu-ru-chan-pin-ming-cheng-bu-chao-guo-60-zi') : $t('qing-shu-ru')
                            "
                        ></el-input>
                        <div class="en-tips" v-if="!isChinese">
                            {{ $t('qing-shu-ru-chan-pin-ming-cheng-bu-chao-guo-60-zi') }}
                        </div>
                    </div>
                    <div class="input2">
                        <el-input
                            v-model="form.enName"
                            :placeholder="$t('qing-shu-ru-chan-pin-ying-wen-pin-ming-mei-you-ke-bu-tian')"
                        ></el-input>
                    </div>
                </div>
            </el-form-item>
            <el-form-item prop="brand" :label="$t('pin-pai')"  :rules="rulesRequire">
                <!-- <el-input class="input2" v-model="form.brand"></el-input> -->
                <el-autocomplete
                    v-model="form.brand"
                    :fetch-suggestions="querySearchAsync"
                    :placeholder="$t('qing-shu-ru-pin-pai')"
                    class="input2"
                    @select="handleSelect"
                ></el-autocomplete>

                <el-input
                    class="input2"
                    v-model="form.enBrand"
                    :placeholder="$t('qing-shu-ru-chan-pin-pin-pai-ying-wen-fan-yi')"
                ></el-input>
            </el-form-item>
            <el-form-item prop="model" :label="$t('chan-pin-xing-hao')"  :rules="rulesRequire">
                <div class="input-list">
                    <div class="input2">
                        <el-input
                            class="input2"
                            v-model="form.model"
                            maxlength="60"
                            :placeholder="$t('qing-shu-ru-chan-pin-xing-hao-bu-chao-guo-60-ge-zi-fu')"
                        ></el-input>
                    </div>
                    <div class="input2">
                        <el-input
                            class="input2"
                            v-model="form.enModel"
                            :placeholder="
                                isChinese ? $t('qing-shu-ru-chan-pin-xing-hao-ying-wen-fan-yi') : $t('qing-shu-ru')
                            "
                        ></el-input>
                        <div class="en-tips" v-if="!isChinese">
                            {{ $t('qing-shu-ru-chan-pin-xing-hao-ying-wen-fan-yi') }}
                        </div>
                    </div>
                </div>
            </el-form-item>
            <!-- <el-form-item prop="enName" :label="$t('ying-wen-pin-ming')">
              
            </el-form-item> -->

            <el-form-item prop="productCategoryId" :label="$t('chan-pin-lei-bie')"  :rules="rulesRequire">
                <el-cascader
                    v-model="form.productCategoryId"
                    :props="optionProps"
                    :options="categories"
                    :placeholder="$t('qing-xuan-ze-chan-pin-lei-bie')"
                    class="input2"
                    @change="select"
                    :show-all-levels="false"
                >
                </el-cascader>
            </el-form-item>
            <el-form-item prop="origin1" :label="$t('chan-di')">
                <!-- <el-input class="input3" v-model="form.origin"></el-input> -->
                <el-cascader
                    :props="countryProps"
                    :options="countrys"
                    class="input2"
                    v-model="form.origin1"
                    :show-all-levels="false"
                ></el-cascader>
            </el-form-item>
            <el-form-item prop="applicationField" :label="$t('ying-yong-ling-yu')">
                <!-- <el-cascader
                    :props="optionProps1"
                    :options="applications"
                    :show-all-levels="false"
                    v-model="form.applicationField"
                    placeholder="请选择或者手动输入文字后按回车添加"
                    filterable
                >
                </el-cascader> -->

                <div class="right">
                    <ApplicationField
                        ref="application"
                        class="input2"
                        :ids.sync="form.applicationField"
                        :label.sync="form.customApplicationField"
                        :applicationField.sync="applicationField"
                    />
                    <div class="input2" v-if="form.customApplicationField">
                        <create
                            v-model="form.enCustomApp"
                            :placeholder="$t('qing-shu-ru-shou-dong-tian-jia-nei-rong-de-ying-wen-fan-yi')"
                        />
                    </div>
                </div>
            </el-form-item>

            <el-form-item prop="productTagIds" :label="$t('chan-pin-biao-qian')">
                <!-- <el-cascader
                    v-model="form.productTagIds"
                    :props="optionProps1"
                    :options="tags"
                    :show-all-levels="false"
                >
                </el-cascader> -->
                <div class="right">
                    <div class="input2">
                        <ProductTags
                            :ids.sync="form.productTagIds"
                            :label.sync="form.customTag"
                            :tags.sync="tags"
                        ></ProductTags>
                    </div>

                    <div class="input2" v-if="form.customTag">
                        <create
                            v-model="form.enCustomTag"
                            :placeholder="$t('qing-shu-ru-shou-dong-tian-jia-nei-rong-de-ying-wen-fan-yi')"
                        />
                    </div>
                </div>
            </el-form-item>

            <el-form-item prop="averageLeadTime" :label="$t('ping-jun-jiao-huo-zhou-qi')">
                <el-input
                    class="input2"
                    v-model="form.averageLeadTime"
                    :placeholder="$t('qing-shu-ru-ping-jun-jiao-huo-zhou-qi')"
                ></el-input>
                <!-- <el-input class="input2" v-model="form.averageLeadTime" placeholder="请输入交货周期英文翻译"></el-input> -->
            </el-form-item>
            <el-form-item prop="offlineExperience" :label="$t('imt-zhan-ting-xian-xia-ti-yan-1')">
                <el-radio-group v-model="form.offlineExperience">
                    <el-radio :label="item.value" v-for="item in boolOptions" :key="item.value">{{
                        $t(item.label)
                    }}</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item prop="introduction" :label="$t('chan-pin-jian-jie')">
                <el-input
                    class="input2"
                    maxlength="300"
                    :placeholder="$t('tips1')"
                    type="textarea"
                    v-model="form.introduction"
                    :autosize="{ minRows: 6 }"
                    show-word-limit
                    resize="none"
                ></el-input>

                <el-input
                    class="input2"
                    maxlength="300"
                    :placeholder="$t('qing-shu-ru-chan-pin-jian-jie-ying-wen-fan-yi')"
                    type="textarea"
                    v-model="form.enIntroduction"
                    :autosize="{ minRows: 6 }"
                    show-word-limit
                    resize="none"
                ></el-input>
            </el-form-item>

            <el-divider></el-divider>

            <div class="userTitle" style="margin-bottom: 0px;">
                <span>{{ $t('xiang-xi-can-shu') }}</span>
                <p class="dividingLine"></p>

                <el-button class="zhedie" size="small" @click="showMore = !showMore"
                    >{{ showMore ? $t('zhe-die') : $t('zhan-kai') }}
                    <i class="el-icon-arrow-down" :class="{ rotateIcon: showMore }"></i
                ></el-button>
            </div>

            <div v-if="showMore" style="padding-top: 30px;">
                <el-form-item
                    :label="`${$t('can-shu')}${index + 1}`"
                    v-for="(item, index) in productParameters"
                    :key="index"
                    v-if="!item.del"
                >
                    <div class="parameters1">
                        <el-input
                            v-model="item.chName"
                            :placeholder="$t('qing-shu-ru-can-shu-ming-cheng')"
                            class="name"
                            :disabled="item.isDefault"
                        ></el-input>
                        <span v-if="isChinese">
                            {{ $t('ying-wen') }}
                        </span>
                        <el-input
                            v-model="item.enName"
                            :placeholder="$t('can-shu-ying-wen-ming-cheng')"
                            class="name2"
                            :disabled="item.isDefault"
                        ></el-input>

                        <span>
                            {{ $t('shu-ju') }}
                        </span>

                        <el-input
                            v-model="item.value"
                            :disabled="hasChild(item)"
                            :placeholder="$t('qing-shu-ru-can-shu-shu-ju')"
                            class="value"
                        ></el-input>

                        <el-button class="add" type="warning" size="small" @click="addParamater(item, index)" plain>{{
                            $t('tian-jia-zi-can-shu')
                        }}</el-button>
                        <el-button
                            class="del"
                            type="danger"
                            v-if="!hasChild(item)"
                            size="small"
                            icon="el-icon-delete"
                            @click="del(item, index)"
                        ></el-button>
                    </div>
                    <div
                        class="parameters2"
                        v-for="(child, childIndex) in item.children"
                        :key="childIndex"
                        v-if="!child.del"
                    >
                        <span> {{ $t('zi-can-shu') }}{{ childIndex + 1 }} </span>
                        <el-input
                            v-model="child.chName"
                            :placeholder="$t('qing-shu-ru-zi-can-shu-ming-cheng')"
                            :disabled="child.isDefault"
                            class="name"
                        ></el-input>
                        <span v-if="isChinese">
                            {{ $t('ying-wen') }}
                        </span>
                        <el-input
                            v-model="child.enName"
                            :placeholder="$t('zi-can-shu-ying-wen-ming-cheng')"
                            :disabled="child.isDefault"
                            class="name2"
                        ></el-input>

                        <span>
                            {{ $t('shu-ju') }}
                        </span>

                        <el-input
                            v-model="child.value"
                            :placeholder="$t('qing-shu-ru-can-shu-shu-ju')"
                            class="value"
                        ></el-input>

                        <el-button
                            class="del"
                            type="danger"
                            size="small"
                            icon="el-icon-delete"
                            @click="del(child, childIndex, item.children, index)"
                        ></el-button>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button type="warning" size="small" @click="addParamater()">{{
                        $t('xin-zeng-can-shu')
                    }}</el-button>
                </el-form-item>
            </div>

            <el-divider></el-divider>

            <div class="userTitle">
                <span>{{ $t('wen-jian-shang-chuan') }}</span>
                <p class="dividingLine"></p>
            </div>

            <el-form-item prop="pdf" :label="$t('chan-pin-shou-ce')">
                <div class="input3">
                    <file-upload v-model="form.pdf1" :size="10" accept=".pdf">
                        <div slot="tips">
                            {{ $t('pdftips') }}
                        </div>
                    </file-upload>
                </div>
            </el-form-item>

            <el-form-item prop="img" :label="$t('chan-pin-zhu-tu')" :rules="rulesRequire">
                <div class="tips">
                    {{ $t('ruletips1') }}<br />
                    {{ $t('ruletips2') }}
                </div>

                <div class="uploadInfo">
                    <video-upload
                        v-if="isVip"
                        :ratio="[1, 1]"
                        name="video"
                        v-model="form.video"
                        :size="50"
                        :imgWidth="148"
                    >
                    </video-upload>

                    <multi-upload :limit="isVip ? 100 : 2" v-model="form.img" backString></multi-upload>
                </div>
            </el-form-item>

            <el-form-item prop="detailImg">
                <span slot="label" v-html="$t('tu-wen-xiang-qing')"></span>

                <div class="tips">
                    {{ $t('detailtips') }}
                </div>
                <!-- <multi-upload v-model="form.detailImg"></multi-upload> -->
                <div style="margin-right: 30px; max-width: 882px;">
                    <rich-text v-model="form.detailImg"></rich-text>
                </div>
            </el-form-item>

            <!-- <el-form-item prop="number" label="编号">
                <el-input v-model="form.number"></el-input>
            </el-form-item>
            <el-form-item prop="customsHsCode" label="海关HS编码">
                <el-input v-model="form.customsHsCode"></el-input>
            </el-form-item>
            <el-form-item prop="price" label="货值">
                <el-input-number type="number" v-model="form.price"></el-input-number>
            </el-form-item>
            <el-form-item prop="entryRecordNumber" label="进境备案单号">
                <el-input v-model="form.entryRecordNumber"></el-input>
            </el-form-item>
            <el-form-item prop="importDeclarationNumber" label="进口报关单号">
                <el-input v-model="form.importDeclarationNumber"></el-input>
            </el-form-item>
            <el-form-item prop="exitRecordNumber" label="出境备案单号">
                <el-input v-model="form.exitRecordNumber"></el-input>
            </el-form-item>
            <el-form-item prop="buyersName" label="采购商名称">
                <el-input v-model="form.buyersName"></el-input>
            </el-form-item>
            <el-form-item prop="deviceStatus" label="设备状态">
                <el-select v-model="form.deviceStatus">
                    <el-option v-for="item in status" :key="item.value" :value="item.value" :label="item.label">
                    </el-option>
                </el-select>
            </el-form-item> -->

            <!--<el-form-item prop="visits" label="访问次数">
                <el-input-number type="number" v-model="form.visits"></el-input-number>
            </el-form-item>-->
            <el-form-item class="bottom-btn">
                <el-button @click="onSave" :loading="saving" type="warning">{{ $t('ti-jiao') }}</el-button>
                <el-button :loading="saving" type="primary" @click="$refs.productPreview.showDialog = true">{{
                    $t('yu-lan')
                }}</el-button>
                <el-button :loading="saving" v-if="form.id" type="danger" @click="onDelete">{{
                    $t('shan-chu')
                }}</el-button>
                <el-button v-else @click="saveStorage" :loading="saving">{{ $t('bao-cun') }}</el-button>
                <el-button @click="$router.go(-1)">{{ $t('fan-hui') }}</el-button>
            </el-form-item>
        </el-form>

        <product-detail
            ref="productPreview"
            :productInfo="form"
            :productParameters="productParameters"
            :applicationField="applicationField"
            :productCategory="productCategory"
            :tags="tags"
        ></product-detail>
    </div>
</template>
<script>
import { boolModels } from '../../utils/AppState';
import formPage from '../../mixins/formPage';
import ApplicationField from '../../components/select/ApplicationField.vue';
import ProductTags from '../../components/select/ProductTags.vue';
import { flatChildren, getFather, getInfoByKey } from '../../utils/Array';
import { mapState } from 'vuex';
import ProductDetail from '../../components/vendor/ProductDetail.vue';
import Create from '../../components/select/Create.vue';
export default {
    name: 'ProductEdit',
    mixins: [formPage],
    created() {
        if (this.$route.query.id) {
            this.productId = this.$route.query.id;
            this.$http
                .get('product/get/' + this.$route.query.id)
                .then(res => {
                    res.origin1 = res.origin1 || [];
                    this.form = res;
                    // this.origins = res.origin1.split(',');
                    this.img = (res.img || '').split(',');
                    this.pdf = (res.pdf1 || '').split(',');
                    this.video = (res.video || '').split(',');
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });

            this.$http.post('/productParameter/getProductParameter?productId=' + this.$route.query.id).then(res => {
                this.productParameters = res;
            });
        } else if (window.localStorage.getItem('producEdit_' + this.$store.state.userInfo.id)) {
            const productEdit = JSON.parse(window.localStorage.getItem('producEdit_' + this.$store.state.userInfo.id));
            this.form = productEdit.form;
            this.img = productEdit.img;
            this.pdf = productEdit.pdf;
            this.video = productEdit.video;
            this.productParameters = productEdit.productParameters;
        }
        this.$http
            .get('/productCategory/allList')
            .then(res => {
                this.categories = this.getTreeData(res, 0);
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });

        this.$http
            .get('/productTag/brand')
            .then(res => {
                this.brands = res;
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });

        this.$http.get('/productTag/country').then(res => {
            this.countrys = this.getTreeData(res, 0, -1);
        });
    },
    data() {
        return {
            saving: false,
            form: {
                origin1: []
            },
            rules: {},
            categories: [],
            all: [],
            optionProps1: {
                value: 'id',
                label: 'chName',
                children: 'children',
                multiple: true,
                emitPath: false
            },
            img: [],
            pdf: [],
            video: [],
            applications: [],
            vendors: [],
            status: [
                { label: '待审核', value: 'PENDING_REVIEW' },
                { label: '上架中', value: 'NOW_ON_SHELF' },
                { label: '已下架', value: 'HAS_BEEN_REMOVED' },
                { label: '审核失败', value: 'AUDIT_FAILURE' }
            ],
            productParameters: [],
            productId: 0,
            showMore: true,
            applicationField: [],
            tags: [],
            brands: [],
            countrys: [],
            origins: []
        };
    },
    computed: {
        ...mapState(['userInfo', 'areaList']),
        optionProps() {
            return {
                value: 'id',
                label: this.$t('chname'),
                children: 'children',
                multiple: false,
                emitPath: false,
                checkStrictly: true,
                expandTrigger: 'hover'
            };
        },
        countryProps() {
            return {
                value: 'id',
                label: this.$t('chname'),
                children: 'children',
                multiple: true,
                emitPath: false,
                expandTrigger: 'hover'
            };
        },
        boolOptions() {
            return [...boolModels].map(item => {
                return {
                    label: item[1],
                    value: item[0]
                };
            });
        },
        saveParamaterJson() {
            const productParameters = [...this.productParameters]
                .filter(item => {
                    return !!item.chName;
                })
                .map(item => {
                    return {
                        ...item,
                        productId: this.productId,
                        children: [...item.children]
                            .filter(child => {
                                return !!child.chName;
                            })
                            .map(child => {
                                let info = { ...child };
                                if (!child.id) {
                                    info.productId = this.productId;
                                    if (item.id) {
                                        info.parent = item.id;
                                    }
                                    info.isShow = true;
                                }
                                return info;
                            }),
                        isShow: !this.hasChild(item)
                    };
                });
            return JSON.stringify(productParameters);
        },
        productCategory() {
            return getInfoByKey(this.categories, this.form.productCategoryId) || {};
        }
    },
    methods: {
        onSave() {
            if (!this.form.chName) {
                this.$message.warning(this.$t('chan-pin-biao-ti-bu-neng-wei-kong'));
                return;
            }
            if (!this.form.enName) {
                this.$message.warning(this.$t('chan-pin-ying-wen-biao-ti-bu-neng-wei-kong'));
                return;
            }
            if (!this.form.brand) {
                this.$message.warning(this.$t('chan-pin-pin-pai-bu-neng-wei-kong'));
                return;
            }
            if (!this.form.model) {
                this.$message.warning(this.$t('chan-pin-xing-hao-bu-neng-wei-kong'));
                return;
            }
            if (!this.form.productCategoryId) {
                this.$message.warning(this.$t('chan-pin-lei-xing-bu-neng-wei-kong'));
                return;
            }
            if (!this.form.img) {
                this.$message.warning(this.$t('chan-pin-zhu-tu-bu-neng-wei-kong'));
                return;
            }
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.form };
            this.saving = true;
            if (!data.id) {
                data.vendorInfoId = this.$store.state.buyersInfo.vendorId;

                window.localStorage.removeItem('producEdit_' + this.$store.state.userInfo.id);
            }
            data.deviceStatus = 'PENDING_REVIEW';

            // data.origin1 = [...this.origins].join(',');
            this.$http
                .post('/product/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.productId = res.id;
                    this.$nextTick(() => {
                        this.$http.post('/productParameter/saveAll', {
                            parameter: this.saveParamaterJson
                        });
                        this.$alert(
                            this.$t('shang-pin-yi-shang-chuan-cheng-gong-qing-nai-xin-deng-dai-xi-tong-shen-he'),
                            this.$t('shang-chuan-cheng-gong'),
                            {
                                confirmButtonText: this.$t('que-ding'),
                                callback: action => {
                                    this.$router.go(-1);
                                }
                            }
                        );
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), {
                type: 'error'
            })
                .then(() => {
                    return this.$http.post(`/product/del/${this.form.id}`);
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        },
        getTreeData(data, num = 0, disabledNum = 2) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (num < disabledNum) {
                    data[i].disabled = true;
                }
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children, num + 1, disabledNum);
                }
            }
            return data;
        },
        addParamater(info, index) {
            const productParameters = [...this.productParameters];
            if (info) {
                info.value = '';
                info.children.push({
                    chName: '',
                    enName: '',
                    value: ''
                });
                productParameters[index] = info;
            } else {
                productParameters.push({
                    chName: '',
                    enName: '',
                    value: '',
                    children: []
                });
            }

            this.productParameters = productParameters;
        },
        del(info, index, list, listIndex) {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), {
                type: 'warning'
            })
                .then(() => {
                    const productParameters = [...this.productParameters];
                    if (info.id) {
                        info.del = true;
                        if (list) {
                            list[index] = info;
                        } else {
                            productParameters[index] = info;
                        }
                    } else if (list) {
                        list.splice(index, 1);
                    } else {
                        productParameters.splice(index, 1);
                    }
                    if (list) {
                        productParameters.children[listIndex] = list;
                    }
                    console.log(list);
                    this.productParameters = productParameters;
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                })
                .catch(e => {});
        },
        hasChild(info) {
            info.children = info.children || [];
            const childNum = [...info.children].filter(item => {
                return !item.del;
            }).length;
            return !!childNum;
        },
        select(value) {
            if (!this.form.id) {
                this.$http
                    .get('/parameter/tree', {
                        categoryId: value
                    })
                    .then(res => {
                        this.productParameters = res.map(item => {
                            return {
                                chName: item.chName,
                                enName: item.enName,
                                value: '',
                                isDefault: true,
                                children: item.children.map(child => {
                                    return {
                                        chName: child.chName,
                                        enName: child.enName,
                                        value: '',
                                        isDefault: true
                                    };
                                })
                            };
                        });
                    })
                    .catch(e => {
                        this.productParameters = [];
                    });
            }

            // this.$nextTick(() => {
            //     if (!this.productId) {
            //         this.productParameters = this.defaultParameters;
            //     }
            // });
        },
        saveStorage() {
            // window.localStorage
            const producEdit = {
                form: this.form,
                img: this.img,
                pdf: this.pdf,
                video: this.video,
                productParameters: this.productParameters
            };
            window.localStorage.setItem('producEdit_' + this.$store.state.userInfo.id, JSON.stringify(producEdit));
            this.$message.success(this.$t('bao-cun-cheng-gong'));
            history.back();
        },
        querySearchAsync(queryString, cb) {
            var restaurants = this.brands;
            var results = queryString
                ? restaurants.filter(state => {
                      return (
                          state.chName.toLowerCase().indexOf(queryString.toLowerCase()) != -1 ||
                          state.enName.toLowerCase().indexOf(queryString.toLowerCase()) != -1
                      );
                  })
                : [];
            cb(
                results.map(item => {
                    return {
                        ...item,
                        value: item.chName
                    };
                })
            );
        },
        handleSelect(item) {
            this.form.enBrand = item.enName;
        }
    },
    components: {
        ApplicationField,
        ProductTags,
        ProductDetail,
        Create
    }
};
</script>
<style lang="scss" scoped>
.userTitle {
    margin-bottom: 30px;
}

.el-divider {
    background-color: #f5f7fa;
    height: 10px;
    margin: 0;
}

.tips {
    font-size: 12px;
    color: #bcc1cc;
    line-height: 22px;
    margin-bottom: 20px;
}

.bottom-btn {
    .el-button {
        height: 40px;
        min-width: 120px;
        &.el-button--warning {
            min-width: 160px;
        }
    }
}

.input2 + .input2 {
    margin-left: 14px;
}

.parameters1 {
    display: flex;
    align-items: center;
    .name {
        width: 186px;
    }
    span {
        font-size: 12px;
        color: #565b66;
        line-height: 17px;
        min-width: 46px;
        margin-right: 10px;
        text-align: right;
    }
    .name2 {
        width: 130px;
    }

    .value {
        width: 161px;
    }

    .el-button {
        height: 36px;
        border-width: 0;
        &.add {
            min-width: 84px;
            margin-left: 10px;
        }

        &.del {
            width: 36px;
            padding: 9px 5px;
            font-size: 16px;
        }
    }
}
.uploadInfo {
    display: flex;
    .single-upload {
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

.right {
    display: flex;
    .input2 {
        &:nth-child(n + 2) {
            margin-left: 14px;
        }
    }
}

.parameters2 {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .name {
        width: 130px;
    }
    .name2 {
        width: 130px;
    }

    .value {
        width: 115px;
    }

    span {
        font-size: 12px;
        color: #565b66;
        line-height: 17px;

        min-width: 46px;
        margin-right: 10px;
        text-align: right;
    }

    .el-button {
        height: 36px;
        border-width: 0;

        &.del {
            width: 36px;
            padding: 9px 5px;
            font-size: 16px;
            margin-left: 10px;
        }
    }
}

.userTitle {
    position: relative;
    border: none;
    .zhedie {
        position: absolute;
        right: 30px;
        top: 13px;
        border-width: 0px;
        color: #878d99;

        .el-icon-arrow-down {
            transition: transform ease-in-out 0.3s;
            &.rotateIcon {
                transform: rotate(-180deg);
            }
        }
    }
}
.dividingLine {
    height: 1px;
    background-color: #dcdfe6;
}

.isEn {
    .parameters1 {
        .name {
            width: 150px;
        }
        .name2 {
            width: 150px;
            margin-left: 18px;
        }
    }

    .parameters2 {
        .name {
            width: 180px;
        }
        .name2 {
            width: 180px;
            margin-left: 10px;
        }

        .value {
            width: 120px;
        }
    }
}
</style>
